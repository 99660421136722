import { post } from '@/utils/request'

//广告列表
export function bannerLinkList(data) {
    return post('admin/banner_link/bannerLinkList', data)
}
//更新广告
export function updateBannerLink(data) {
    return post('admin/banner_link/updateBannerLink', data)
}
//增加广告
export function addBannerLink(data) {
    return post('admin/banner_link/addBannerLink', data)
}
//删除广告
export function deleteBannerLink(data) {
    return post('admin/banner_link/deleteBannerLink', data)
}
//轮播图列表
export function getBannerList(data) {
    return post('admin/banner/list', data)
}
//商品列表
export function getGoodsList(data) {
    return post('admin/banner_link/goods', data)
}